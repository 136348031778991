<template>
  <a-result
    status="404"
    title="404"
    sub-title="出错啦~！页面不存在"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    "
  >
    <template #extra>
      <a-button type="primary" @click="toIndex"> 去首页 </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  methods: {
    toIndex() {
      this.$router.push("/layout/index");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-result-image {
  margin: 0;
}
</style>